import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "about"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.data.summary !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", null, _toDisplayString($setup.t("rc-about-video", {
      ns: $setup.TranslationNamespaceEnum.customTranslations
    })), 1 /* TEXT */),
        _createElementVNode("p", {
          innerHTML: $setup.data.summary
        }, null, 8 /* PROPS */, _hoisted_2)
      ]))
    : _createCommentVNode("v-if", true)
}
<script lang="ts" setup>
import { useTranslation } from 'i18next-vue';
import { TranslationNamespaceEnum } from "../../../locale/settings/translation-namespaces.js";

  const props = defineProps<{
    customData?: Record<string, any>;
  }>();
  const data = props.customData ?? { keyTakeaways: '' } as { keyTakeaways: string };
  const { t } = useTranslation();
</script>

<template>
  <div v-if="data.keyTakeaways !== ''" class="key-takeaways vid-space">
    <h5>{{ t("rc-list-header", {
      ns: TranslationNamespaceEnum.customTranslations
    }) }}</h5>
    <ul class="list" v-html="data.keyTakeaways"></ul>
  </div>
</template>
<script lang=ts setup>
import { useTranslation } from 'i18next-vue';
import { TranslationNamespaceEnum } from "../../../locale/settings/translation-namespaces.js";

  const props = defineProps<{
    customData?: Record<string, any>;
  }>();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const data = props.customData!;
  const { t } = useTranslation();
</script>

<template>
  <div v-if="data.summary !== null" class="about">
    <h5>{{ t("rc-about-video", {
      ns: TranslationNamespaceEnum.customTranslations
    }) }}</h5>
    <p v-html="data.summary"></p>
  </div>
</template>